import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthenticationService } from '@app/auth/service';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ValidarTokenGuard implements CanActivate, CanLoad {
  
  constructor( private authService: AuthenticationService,
               private router: Router ){}


  canActivate(): Observable<boolean> | boolean {
    return this.authService.validateToken()
            .pipe(
              tap( valid => {
                if ( !valid ) {
                  this.router.navigateByUrl('/auth');
                }
              })
            );
  }

  canLoad(): Observable<boolean> | boolean {
    return this.authService.validateToken()
      .pipe(
          tap( valid => {
          if ( !valid ) {
            this.router.navigateByUrl('/auth');
          }
        })
      );
  }
}