import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IContentHeader } from '../types';

@Injectable({
  providedIn: 'root',
})
export class ContenteHeaderService {
  headerContent = new Subject<IContentHeader>();

  getHeaderContent() {
    return this.headerContent.asObservable();
  }
}
