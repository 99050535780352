import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './components/content-header/breadcrumb/breadcrumb.component';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorComponent } from './components/error/error.component';

const components = [ContentHeaderComponent, BreadcrumbComponent, ErrorComponent];
const modules = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbModule];

@NgModule({
  imports: [TranslateModule.forChild({ extend: true }), ...modules],
  declarations: [...components],
  entryComponents: [],
  providers: [],
  exports: [TranslateModule, ...modules, ...components],
})
export class SharedModule {}
