export const locale = {
  lang: 'es',
  data: {
    MENU: {
      HOME: 'Inicio',
      NURSINGHOMES: 'Residencias',
      LEADS: 'Leads',
      REVIEWS: 'Reviews',
      ADMIN: 'Administración',
      SAMPLE: 'Prueba',
    },
  },
};
