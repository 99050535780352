export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      NURSINGHOMES: 'Nursing homes',
      LEADS: 'Leads',
      REVIEWS: 'Reviews',
      ADMIN: 'Administration',
      SAMPLE: 'Sample',
    },
  },
};
