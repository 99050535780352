export const locale = {
  lang: 'es',
  data: {
    BREADCRUMB: {
      HOME: 'Inicio',
      NURSINGHOMES: 'Residencias',
      LEADS: 'Leads',
      REVIEWS: 'Reviews'
    },
  },
};
