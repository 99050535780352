import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

import { AuthLoginResponse, AuthRegisterResponse, AuthRenewResponse, UserBody } from '../interfaces/interfaces';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  private url = `${environment.apiUrl}/api/v1/auth`;
  private apiKey: string = environment.apiKey;
  private _user: UserBody;

  get user() {
    return { ...this._user };
  }

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient,
    private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */

  register(firstName: string, lastName: string, email: string, password: string) {

    const url = `${this.url}/register`;
    const body = { firstName, lastName, email, password };
    const headers = new HttpHeaders({
      'x-api-key': this.apiKey,
      'Content-Type': 'application/json; charset=utf-8',
    });

    return this._http.post<AuthRegisterResponse>(url, body, { headers })
      .pipe(
        tap((resp) => {
          if (resp) {
            localStorage.setItem('token', resp.token!);
            this._user = {
              firstName: resp.user.firstName,
              lastName: resp.user.lastName,
              local: resp.user.local,
              role: resp.user.role,
              _id: resp.user._id
            }
          }
        }),
        map(resp => resp.user),
        catchError(err => of(err.error))
      );

  }

  login(email: string, password: string) {

    const url = `${this.url}/login`;
    const body = { email, password }
    const headers = new HttpHeaders({
      'x-api-key': this.apiKey,
      'Content-Type': 'application/json; charset=utf-8',
    });

    return this._http.post<AuthLoginResponse>(url, body, { headers })
      .pipe(
        tap(resp => {
          if (resp.success) {
            localStorage.setItem('token', resp.body.token!);
            this._user = {
              firstName: resp.body.firstName,
              lastName: resp.body.lastName,
              local: resp.body.local,
              role: resp.body.role,
              _id: resp.body._id
            }
          }
        }),
        map(resp => resp.success),
        catchError(err => of(false))
      )
  }

  validateToken(): Observable<boolean> {

    const url = `${ this.url }/renew`;
    const headers = new HttpHeaders({
      'x-api-key': this.apiKey,
      'Content-Type': 'application/json; charset=utf-8',
    })
      .set('token', localStorage.getItem('token') || '' );

    return this._http.get<AuthRenewResponse>( url, { headers } )
        .pipe(
          map( resp => {
            localStorage.setItem('token', resp.token! );
            this._user = {
              firstName: resp.autenticatedUser?.firstName,
              lastName: resp.autenticatedUser?.lastName,
              local: resp.autenticatedUser?.local,
              role: resp.autenticatedUser?.role,
              _id: resp.autenticatedUser?._id
            }

            return resp?.ok;
          }),
          catchError( err => of(false) )
        );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    // notify
    this.currentUserSubject.next(null);
  }
}
