import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home',
  },
  {
    id: 'sample',
    title: 'Sample',
    translate: 'MENU.SAMPLE',
    type: 'item',
    icon: 'file',
    url: 'sample',
  },
  {
    id: 'management',
    title: 'Gestión',
    type: 'section',
  },
  {
    id: 'residencias',
    title: 'Nursing Homes',
    translate: 'MENU.NURSINGHOMES',
    type: 'item',
    icon: 'map',
    url: 'residencias',
  },
  {
    id: 'leads',
    title: 'Leads',
    translate: 'MENU.LEADS',
    type: 'item',
    icon: 'user',
    url: 'leads',
  },
  {
    id: 'reviews',
    title: 'Reviews',
    translate: 'MENU.REVIEWS',
    type: 'item',
    icon: 'list',
    url: 'reviews',
  },
  {
    id: 'admin',
    title: 'Administration',
    translate: 'MENU.ADMIN',
    type: 'item',
    icon: 'settings',
    url: 'admin',
  },
];
