import { Component, Input } from '@angular/core';
import { CoreTranslationService } from '@core/services/translation.service';

import { locale as en } from './i18n/en';
import { locale as es } from './i18n/es';

// Breadcrumb component interface
export interface Breadcrumb {
  type?: string;
  alignment?: string;
  links?: Array<{
    name?: string;
    translateKey?: string;
    isLink: boolean;
    link?: string;
  }>;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
  @Input() breadcrumb: Breadcrumb;

  constructor(private _coreTranslationService: CoreTranslationService) {
    this._coreTranslationService.translate(es, en);
  }
}
