export const locale = {
  lang: 'en',
  data: {
    BREADCRUMB: {
      HOME: 'Home',
      NURSINGHOMES: 'Nursing Homes',
      LEADS: 'Leads',
      REVIEWS: 'Reviews'
    },
  },
};
