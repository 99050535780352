import { Component, OnInit } from '@angular/core';
import { ContenteHeaderService } from '@app/shared/services/content-header.service';
import { Subscription } from 'rxjs';
import { ContentHeader } from './interfaces/content-header.interface';

// import { locale as en } from './i18n/en';
// import { locale as es } from './i18n/es';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
})
export class ContentHeaderComponent implements OnInit {
  contentHeaderSub: Subscription;
  contentHeader: ContentHeader;

  constructor(private contentHeaderService: ContenteHeaderService) {}

  ngOnInit(): void {
    this.contentHeaderSub = this.contentHeaderService.getHeaderContent().subscribe(contentHeader => {
      this.contentHeader = contentHeader;
    });
  }
}
